@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "digital-7";
  src: local("digital-7"), url("./fonts/digital-7.ttf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* starts for kopya */
.layout-light {
  --sidebar-shadow: 0px 4px 50px #d9ddfc;
  --sidebar-border: 1px solid transparent;
  --card-shadow: 0px 4px 30px rgba(221, 224, 255, 0.54);
}

.bg-main {
  background-color: #eff3f8;
}

.main-purple {
  color: #6366f1;
}

.bg-main-purple {
  background: #6366f1;
}

.text-color {
  color: #1e293b;
}

.layout-container {
  min-height: 100vh;
}

.layout-sidebar {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 16rem;
  background: #ffffff;
  border-right: var(--sidebar-border);
  box-shadow: var(--sidebar-shadow);
  z-index: 0;
  display: flex;
  flex-direction: column;
  transition-duration: 500ms;
}

.card-custom {
  background: #ffffff;
  box-shadow: var(--card-shadow);
  border: 1px solid #dfe7ef;
  border-radius: 15px;
}

.border-1-custom {
  border: 1px solid #dfe7ef;
  border-radius: 15px;
}

@media screen and (min-width: 992px) {
  .layout-container.layout-static .layout-content-wrapper {
    margin-left: 16rem;
    transition: margin-left var(--transition-duration);
    transition-duration: 500ms;
  }

  .layout-container.layout-overlay .layout-content-wrapper,
  .layout-container.layout-static-inactive .layout-content-wrapper {
    margin-left: 0;
    transition-duration: 500ms;
  }

  .layout-container.layout-static-inactive .layout-sidebar {
    transition-duration: 500ms;
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 991px) {
  .layout-container .layout-content-wrapper {
    margin-left: 0;
    padding: 1rem;
    transition-duration: 500ms;
  }

  .layout-container.layout-mobile-active .layout-sidebar {
    transform: translateX(0);
  }

  .layout-container.layout-static-inactive .layout-sidebar {
    transition-duration: 500ms;
    transform: translateX(-100%);
  }

  /* .scroll-blocked {
    overflow: hidden;
  } */
}

/*  */
@media screen and (max-width: 991px) {
  .layout-container .layout-mask {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .layout-sidebar {
    z-index: 999;
  }
}

@media screen and (max-width: 991px) {
  .layout-mobile-active .layout-mask {
    display: block;
    animation: fadein 500ms;
  }
}

/*  */
.layout-sidebar .layout-menu-container {
  overflow: auto;
  flex: 1 1;
  padding-bottom: 2rem;
}

.layout-topbar,
.layout-topbar .topbar-start {
  display: flex;
  align-items: center;
}

.layout-topbar {
  margin-bottom: 2rem;
  justify-content: space-between;
}

.layout-topbar,
.layout-topbar .topbar-start {
  display: flex;
  align-items: center;
}

.layout-topbar .topbar-start .topbar-menubutton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
  transition: background-color var(--transition-duration);
}

@media screen and (min-width: 992px) {
  .layout-container .content-breadcrumb {
    display: none;
  }
}

.content-breadcrumb {
  margin-bottom: 2rem;
  padding: 0 0.5rem;
}

/* ends for kopya */

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.outer-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.image-container {
  position: relative;
  width: 140px;
  height: 90px;
}

.z-150 {
  z-index: 150;
}

.w-128 {
  width: 512px;
}

.py-35-custom {
  height: 34px;
}

.w-22-custom {
  width: 110px;
}

@media print {
  .hide-for-print {
    display: none;
    margin: 0;
    overflow: hidden;
  }

  .label-section-to-print {
    display: block;
    visibility: visible;
    position: absolute;

    transform: rotate(90deg);
    transform-origin: bottom left;

    position: absolute;
    top: -100vw;
    left: 0;

    height: 100vw;
    width: 100vh;
    overflow: auto;
  }

  .export-label-section-to-print {
    display: block;
    visibility: visible;
    position: absolute;

    transform: rotate(90deg);
    transform-origin: bottom left;

    position: absolute;
    top: -100vw;
    left: 0;

    height: 100vw;
    width: 100%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    overflow: auto;

    border: 1px solid #000000;
  }

  .regular-page-to-print {
    display: block;
    visibility: visible;
    position: absolute;

    height: 100%;
    width: 100%;
    overflow: auto;
  }

  .regular-page-section-to-print {
    display: block;
    visibility: visible;
  }

  .print-button {
    visibility: none;
  }

  .text-barcode-slip {
    font-size: 11px;
    line-height: 12px;
  }

  .font-size-10 {
    font-size: 10px;
    line-height: 12px;
  }

  .internal-use-text {
    font-size: 14px;
    line-height: 12px;
    font-weight: bold;
  }
}

.text-packaging-plu {
  font-size: 23px;
  line-height: 12px;
  font-weight: bold;
}

.line-height-10 {
  line-height: 18px;
}
.line-height-8 {
  line-height: 15px;
}
.line-height-1 {
  line-height: 10px;
}

.w-custom-100px {
  width: 110px;
}

.rotate-90 {
  transform: rotate(90deg);
  width: 100%;
}

.scale-digital-font {
  font-family: "digital-7";
}

.border-1-gray {
  border: solid 1px rgb(209 213 219);
}

.border-1-bottom-gray {
  border-bottom: solid 1px rgb(209 213 219);
}

.p-paginator {
  float: right;
  padding: 10px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border: 0 none;
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}

.p-paginator .p-paginator-pages .p-paginator-page:hover {
  background: #e7e7e7;
  border-color: #e7e7e7;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #ecfeff;
  border-color: #ecfeff;
  color: #0e7490;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #6b7280;
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}

.p-paginator .p-paginator-first:hover,
.p-paginator .p-paginator-prev:hover,
.p-paginator .p-paginator-next:hover,
.p-paginator .p-paginator-last:hover {
  background: #e7e7e7;
  border-color: #e7e7e7;
}

.create-order-inventory-table-parent th:nth-child(3) > .p-column-header-content,
.manufacture-recipe-items-table th:nth-child(3) > .p-column-header-content,
.manufacture-recipe-items-table th:nth-child(4) > .p-column-header-content,
.manufacture-recipe-items-table th:nth-child(5) > .p-column-header-content,
.raw-inventory-table-parent th:nth-child(3) > .p-column-header-content,
.raw-inventory-table-parent th:nth-child(4) > .p-column-header-content,
.raw-inventory-table-parent th:nth-child(5) > .p-column-header-content,
.manufactured-inventory-table-parent th:nth-child(4) > .p-column-header-content,
.manufactured-inventory-table-parent th:nth-child(5) > .p-column-header-content,
.manufactured-inventory-table-parent th:nth-child(6) > .p-column-header-content,
.manufactured-inventory-table-parent th:nth-child(7) > .p-column-header-content,
.manufactured-inventory-table-parent
  th:nth-child(8)
  > .p-column-header-content {
  text-align: end;
  display: block;
}

.raw-inventory-table-child th:nth-child(4) > .p-column-header-content,
.raw-inventory-table-child th:nth-child(5) > .p-column-header-content,
.raw-inventory-table-child th:nth-child(6) > .p-column-header-content,
.raw-inventory-table-child th:nth-child(3) > .p-column-header-content {
  text-align: start;
}

.manufacture-recipe-items-children-table
  th:nth-child(3)
  > .p-column-header-content,
.manufacture-recipe-items-children-table
  th:nth-child(4)
  > .p-column-header-content {
  text-align: end;
  display: flex;
}

.manufacture-recipe-items-children-table
  th:nth-child()
  > .p-column-header-content {
  text-align: end;
  display: block;
}

.p-column-header-content {
  padding: 0.5rem;
  text-align: start;
}

.packaging-items-table {
  width: 100%;
  word-wrap: break-word;
  table-layout: fixed;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-15 {
  font-size: 15px;
}

.p-row-editor-cancel {
  font-size: 24px;
  background-color: #d8d8d8;
}

.css-b62m3t-container .css-qj5diu-control {
  height: 100%;
}
